import { userState } from '../../atoms/store'
import { useSetRecoilState } from "recoil";
import { getprofile } from '../../lib/api/myspace'
import { useEffect } from 'react';
import { getCookies } from 'cookies-next';

const UserManager = () => {
    const setUserData = useSetRecoilState(userState);

    useEffect(() => {      
        const _fetchUserData = async () => {                        
            const resp = await getprofile();
            if(resp.success) {     
                setUserData(resp.data);
            }
        }

        if(getCookies('eccauthToken')?.eccauthToken) {
            _fetchUserData();
        }else{
            setUserData(null);
        }       
        
      }, [])

    return (<></>)


}

export default UserManager;