import React from 'react'
import Head  from 'next/head'
import Pixel from './pixel'

const FacebookPixel =  () => {
  return(
    <Head>          
        <Pixel></Pixel>
    </Head>
  )
}

export default FacebookPixel;