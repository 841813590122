import { useEffect } from 'react';
import { configStoreState } from '../../atoms/store.jsx'
import { useSetRecoilState } from "recoil";

const ConfigManager = () => {
	const setConfigStore = useSetRecoilState(configStoreState);
	
	useEffect(() => {			
		const _fetchConfigStore = () => {
			if(process.env.NEXT_PUBLIC_LOCALHOST_SHOPID ==''){
				if(location.host == process.env.NEXT_PUBLIC_ECCEDICIONES_HOST){
					setConfigStore(1);						
				}else if(location.host == process.env.NEXT_PUBLIC_ECCCOMICS_HOST){
					setConfigStore(9);						
				}else{
					setConfigStore(1);						
				}								
			}else{
				setConfigStore(process.env.NEXT_PUBLIC_LOCALHOST_SHOPID);						
			}
			
			
		}			
		_fetchConfigStore();
	}, []);

    return (<></>)
}

export default ConfigManager;
