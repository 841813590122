import sendRequest from './sendRequest';

const BASE_PATH = 'api';

export const getprofile = data =>
    sendRequest(`${BASE_PATH}/customer/myspace/GetCustomerDetail`, {method: 'GET'});

export const updateProfile = data =>
    sendRequest(`${BASE_PATH}/customer/myspace/UpdateCustomer`, {
        body: JSON.stringify(data),
        method: 'PUT',
    });

// myspace/GetBillingAddressList

/**Get Billing Address List */
export const getBillingAddresslist = data =>
    sendRequest(`${BASE_PATH}/CustomerAddress/myspace/GetBillingAddressList`, {
        method: 'GET',
    });

export const updateBillingaddress = data =>
    sendRequest(`${BASE_PATH}/CustomerAddress/myspace/UpdateBillingAddress/${data.id}`, {
        body: JSON.stringify(data)
    });

export const saveBillingaddress = data =>
    sendRequest(`${BASE_PATH}/CustomerAddress/myspace/AddBillingAddress`, {
        body: JSON.stringify(data),
    });

export const getbillingaddressBydetail = data =>
    sendRequest(`${BASE_PATH}/CustomerAddress/myspace/GetBillingAddressDetail/${data}`, {
        method: 'GET',
    });

export const setbillingdefaultAddress = data =>
    sendRequest(`${BASE_PATH}/CustomerAddress/myspace/SetBillingAddressAsDefault?id=${data}`, {
        method: "PATCH"
    });

/*Address Api start Here*/
export const getaddressbydetail = data =>
    sendRequest(`${BASE_PATH}/CustomerAddress/myspace/GetCustomerAddressDetail/${data}`, {
        method: 'GET',
    });

export const getAlladdress = data =>
    sendRequest(`${BASE_PATH}/CustomerAddress/myspace/GetCustomerAddressList`, {
        method: 'GET',
    });

export const saveAddress = data =>
    sendRequest(`${BASE_PATH}/CustomerAddress/myspace/AddCustomerAddress`, {
        body: JSON.stringify(data),
    });

export const updateAddress = data =>
    sendRequest(`${BASE_PATH}/CustomerAddress/myspace/UpdateCustomerAddress/${data.id}`, {
        method: 'PUT',
        body: JSON.stringify(data)
    });

export const deleteAddress = data =>
    sendRequest(`${BASE_PATH}/CustomerAddress/admin/DeleteCustomerAddress/${data}`, {
        body: JSON.stringify(data),
    });

export const setdefaultAddress = data =>
    sendRequest(`${BASE_PATH}/CustomerAddress/myspace/SetAddressAsDefault?id=${data}`, {
        method: "PATCH"
    });
/*Address Api start End Here*/

export const getFavrouites = data =>
    sendRequest(`${BASE_PATH}/customer/myspace/GetMyFavouritesList?${data}`, {
        method: 'GET',
    })
export const deleteShippingAddress = data =>
    sendRequest(`${BASE_PATH}/CustomerAddress/myspace/DeleteShippingAddress/${data}`, {
        method: 'DELETE',
  });
export const deleteBillingAddress = data =>
    sendRequest(`${BASE_PATH}/CustomerAddress/myspace/DeleteBillingAddress/${data}`, {
        method: 'DELETE',
    });
