import sendRequest from './sendRequest';

const BASE_PATH = 'api';

export const setProductOrder = data =>
    sendRequest(`${BASE_PATH}/Order/AddProductToCart`, {
        body: JSON.stringify(data),
        method: 'POST',
    });

export const getShoppingCart = data =>
    sendRequest(`${BASE_PATH}/Order/GetShoppingCart?CartUuid=${data}`, {
        method: 'GET',
    });

export const updateProductQty = data =>
    sendRequest(`${BASE_PATH}/Order/ModifyProductQty`, {
        method: 'POST',
        body: JSON.stringify(data)
    });


export const removeProductQty = data =>
    sendRequest(`${BASE_PATH}/Order/DeleteProductFromCart`, {
        method: 'DELETE',
        body: JSON.stringify(data)
    });

export const getAvailableShippingMethods = data =>
    sendRequest(`${BASE_PATH}/Order/myspace/GetShippingMethodsAvailable`, {
        method: 'GET',
    });

export const getShopPhysicalStores = data =>
    sendRequest(`${BASE_PATH}/Shops/GetPhysicalStores`, {
        method: 'GET',
    });

export const setcartShippingMethod = data =>
    sendRequest(`${BASE_PATH}/Order/myspace/SelectShippingMethod?shippingMethodId=${data}`, {
        method: 'POST',
    });

export const setcartshippingaddress = data =>
    sendRequest(`${BASE_PATH}/Order/myspace/SelectShippingAddress`, {
        method: 'POST',
		body: JSON.stringify(data)
    });

export const setOrderMySpaceShippingAddress = (data, orderId) =>
    sendRequest(`${BASE_PATH}/Order/myspace/SelectShippingAddress?shippingAddressId=${data}&orderId=${orderId}`, {
        method: 'POST',
    });

export const selectPickuplocation = data =>
    sendRequest(`${BASE_PATH}/Order/myspace/SelectPickUpLocation`, {
        method: 'POST',
        body: JSON.stringify(data)
    });

export const saveForLaterProduct = data =>
    sendRequest(`${BASE_PATH}/Order/myspace/AddOrRemoveSaveForLaterProduct`, {
        method: 'POST',
        body: JSON.stringify(data)
    });
export const getsaveForLaterProduct = data =>
    sendRequest(`${BASE_PATH}/Order/myspace/GetSaveForLaterProducts`, {
        method: 'GET',
    });

export const convertprodtocart = data =>
    sendRequest(`${BASE_PATH}/Order/myspace/ConvertSaveForLaterToCartProduct?ProductId=${data}`, {
        method: 'POST',
    });

export const setBillAddress = data =>
    sendRequest(`${BASE_PATH}/Order/myspace/SelectBillingAddress?billingAddressId=${data}`, {
        method: 'POST',
    });

export const setSameBillAddress = data =>
    sendRequest(`${BASE_PATH}/Order/myspace/SetBillingSameAsShippingAddress`, {
        method: 'POST',
    });

export const checkifsplitOrder = data =>
    sendRequest(`${BASE_PATH}/Order/SetSplitOrder`, {
        method: 'POST',
        body: JSON.stringify(data)
    });

export const checkcouponvalidity = data =>
    sendRequest(`${BASE_PATH}/coupons/UseCoupon`, {
        method: 'PUT',
        body: JSON.stringify(data)
    });

///api/Coupons/DeleteCouponFomCart
export const deleteCoupon = data =>
    sendRequest(`${BASE_PATH}/Coupons/DeleteCouponFomCart`, {
        method: 'DELETE',
        body: JSON.stringify(data)
    });

export const isInvoiceRequired = data =>
    sendRequest(`${BASE_PATH}/Order/myspace/SetIsInvoiceRequired?isInvoiceRequired=${data}`, {
        method: 'POST',
    });
